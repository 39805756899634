import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {useState} from 'react';

export default function Contact() {

    const [result, setResult] = useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "6e848a68-d463-4194-b38b-4e2cd66309a8");

        const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
        }).then((res) => res.json());

        if (res.success) {
            console.log("Success", res);
            setResult(res.message);
        } else {
            console.log("Error", res);
            setResult(res.message);
        }
    };


  return (

    <div className="max-w-7xl mx-auto py-10  relative text-slate-700 rounded-xl bg-slate-200 p-8 mt-20" id="contactform">

        <div className="flex justify-between text-2xl text-slate-500 mb-4" >  
            <div className="mx-auto mb-3 uppercase tracking-widest">
                Contact Us
            </div>
        </div>



        <form onSubmit={onSubmit}>
            <input type="hidden" name="access_key" value="" />
            
            <div className="mx-auto ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-6">

                    <div className="col">

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6">

                            <div className="col">
                                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-slate-600 text-left">
                                First name
                                </label>
                                <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 bg-white px-3.5 py-2  ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>
                            <div className="col">
                                <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-slate-600 text-left">
                                Last name
                                </label>
                                <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 bg-white px-3.5 py-2 ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 mt-3">

                            <div className="col">
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-slate-600 text-left">
                                Email
                                </label>
                                <div className="mt-2.5">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 bg-white px-3.5 py-2 ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>
                            <div className="sm:col">
                                <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-slate-600 text-left">
                                Phone number
                                </label>
                                <div className="mt-2.5">
                                <input
                                    type="tel"
                                    name="phone-number"
                                    id="phone-number"
                                    autoComplete="tel"
                                    className="block w-full rounded-md border-0 bg-white px-3.5 py-2 ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col">

                        <div className="">
                            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-slate-600 text-left">
                            Message
                            </label>
                            <div className="mt-2.5">
                            <textarea
                                name="message"
                                id="message"
                                rows={4}
                                className="block w-full h-full rounded-md border-0 bg-white px-3.5 py-2 ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                defaultValue={''}
                            />
                            </div>
                        </div>
                        <div className="h-captcha" data-captcha="true"></div>

                    </div>

                    
                    

                </div>
                <div className="mt-8 flex justify-end">
                    <button type="submit" 
                        className="rounded-md bg-slate-800 px-3.5 py-2.5 text-center text-sm font-semibold text-white hover:bg-slate-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                    Send Message
                    </button>
                </div>
            </div>

            <div className="text-slate-800">
                {result}
            </div>
        </form>

        

    </div>


      
  )

}
