


export default function Hero() {

    return (
        <div className="w-screen h-screen flex-col overflow-x-hidden text-white" >
            <div className="absolute top-0 left-0 bg-slate-900">
                <video className="w-screen h-screen filter blur-md opacity-60" autoPlay loop muted >
                    <source src="/data_video.webm" type='video/webm' />
                    <source src="/data_video.mp4" type="video/mp4" />
                </video>    
            </div>

            <div className="absolute bottom-0 left-0 w-full h-20 bg-gradient-to-t from-slate-900 via-slate-900 to-transparent">

            </div>

            <Navbar />

            <div className="relative flex h-[80%] max-w-7xl mx-auto ">

                <div className="my-auto justify-center align-center mx-auto ">
                    
                    <img src="/hll_logo_white.png" className="h-32 mb-16 mx-auto md:-mt-24" />

                    <div className="text-7xl font-bold ">
                        With risk,<br /> comes opportunity.
                    </div>

                    <div className="text-2xl mt-10">
                        Risk, Trading & Capital Management Solutions
                    </div>

                </div>
            </div>
            

        </div>
       
    )
}


function Navbar() {

    return(
        <nav className="relative flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        {/* <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
              alt=""
            />
          </a>
        </div> */}
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            // onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12 mx-auto text-2xl">
          
            <a key={'about'} href={'#services'} className="text-sm font-medium leading-6 text-white">
              About
            </a>
            <a key={'services'} href={'#services'} className="text-sm font-medium leading-6 text-white">
              Services
            </a>
            <a key={'contact'} href={'#contactform'} className="text-sm font-medium leading-6 text-white">
              Contact
            </a>
          
        </div>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className="text-sm font-semibold leading-6 text-white">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div> */}
      </nav>
    )
}