


export default function Cards() {

    return (

      <div className="relative pt-10 bg-slate-100" id="services">

        <div className="relative ">

      
          <div className="max-w-7xl mx-auto -mt-20" >

              <div className="grid grid-cols-1 md:grid-cols-3 md:gap-6 pb-20">
                  
                <Card title={"Prediction markets and futarchy"} 
                  icon={
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                  }
                  body={
                  "Risk can be harnessed by understanding and assessing likelihood and severity. We believe in and support projects and organisations building platforms to better price and understand markets for risk."
                }/>
                <Card title={"Data sourcing and aggregation"}
                  icon={
                      <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                  }
                  body={
                  "We source and aggregate data from across the world's leading prediction marketplaces, and use this to make judgements about uncertain events."
                }/>
                <Card title={"Risk assessment and capital management"} 
                  icon={
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                  }
                  body={
                  "Algorithms for understanding and appropriately allocating risk capital across a wide range of applications."
                }/>

                <Card title={"Deploying risk capital, liquidity & trading"} 
                  icon={
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  }
                  body={
                    "In marketplaces and risks that we can effectively understand and price, we deploy capital - both active and passive liquidity provision."
                }/>
                <Card title={"Risk parameter configuration"}
                  icon={
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                  }
                  body={
                    "We work with partners to configure and optimise risk parameters and pricing configuration for games-of-chance rules, trading strategies and more."
                }/>
                <Card title={"Trading integrations and tooling"} 
                  icon={
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                  }
                  body={
                    "Building tooling and SDKs to interface with marketplaces for risk, managing positions, exposures and order flow."
                }/>

               

              </div>

          </div>
            

        </div>
      </div>
       
    )
}


function Card({title, icon, body}) {
  return(
    <div className="col bg-slate-200 rounded-xl border border-slate-200 p-6 md:p-8 text-slate-700 text-left
      md:hover:transform md:hover:-translate-y-4 hover:bg-slate-300 transition ease-in-out duration-500 
    ">
      <div className="mb-3">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
        {icon}
        </svg>
      </div>
      <div className="text-3xl font-medium text-slate-800 mb-3 w-[80%] ">
        {title}
      </div>
      <div className="text-xl">
        {body}
      </div>
    </div>
  )
}