import logo from './logo.svg';
import './App.css';

import Hero from './components/hero.tsx';
import Cards from './components/cards.tsx';
import Footer from './components/footer.tsx';
import Partners from './components/partners.tsx';
import Contact from './components/contact.tsx';

function App() {
  return (
    <div className="
      App bg-slate-100 text-slate-800 overflow-x-hidden
    ">

        <Hero />
        <Cards />
        <Partners />
        <Contact />
        <Footer />

      
    </div>
  );
}

export default App;
