


export default function Partners() {

    return (

      <div className="max-w-7xl mx-auto py-10  relative text-slate-700 rounded-xl bg-slate-800 p-8"
        style={{
          background: `url('background.png')`,
          backgroundSize: "fill",
          backgroundPosition:"center",
          backgroundRepeat: "no-repeat",
          backgroundBlendMode: "multiply",
        }}
      >

        <div className="flex justify-between text-2xl text-slate-500 " >  
          <div className="mx-auto mb-3 uppercase tracking-widest">
            Our Partners
          </div>
        </div>

        <div className="flex justify-between " >

            <div className="">
                
                <img src="investors/sig.png" className="h-16" />
                
            </div>

            <div className="">
                
              <img src="investors/jump.png" className="h-16" />

            </div>

            <div className="">
                
              <img src="investors/mirana.png" className="h-16" />

            </div>

            <div className="">
                
              <img src="investors/bluepool.png" className="h-16" />

            </div>

            <div className="">
                
              <img src="investors/manna.png" className="h-16" />

            </div>
            

        </div>
      </div>
       
    )
}


