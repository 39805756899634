


export default function Footer() {

    return (

      <div className="relative text-slate-700 mt-16">

        
      
        <div className="max-w-7xl mx-auto py-10 flex justify-between align-center" >

            <div className="my-auto">
              <img src="/hll_logo_slate700.png" className="h-16 mx-auto " />
            </div>

            <div className="my-auto">
                
              Copyright © {new Date().getFullYear()} Hatea Loop Limited 
              <span className="text-slate-300 mx-3">|</span> 
              All rights reserved.
            </div>


            


            <div className="my-auto">
                
              About
              <span className="text-slate-300 mx-3">|</span> 
              Services
              <span className="text-slate-300 mx-3">|</span> 
              Contact 

            </div>
            

        </div>
      </div>
       
    )
}


